:root {
    --subtle-detail-color: #e5f5ff;
    --subtle-detail-color-contrast: black;
    --catch-detail-color: #3a3aeb;
    --catch-detail-color-contrast: white;
    --alert-color: #fee4d1;
    --background-color: white;
    --foreground-color: black;
    --popup-border: white;
    --shadow-color: #00000066;
    
    --return-to-the-map-height: 5em;
    --variable-title-height: 0px; /*Set by javascript dynamically*/
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    color: var(--foreground-color);
    font-family: 'Helvetica Neue', Arial, sans-serif;
}

a {
    color: var(--foreground-color)
}

#topleft-tools svg {
    fill: var(--foreground-color) !important;
    stroke: var(--foreground-color) !important;
}

#topleft-tools svg path {
    fill: var(--foreground-color) !important;
    stroke: var(--foreground-color) !important;
}

.direction-svg svg path{
     fill: var(--catch-detail-color) !important;
}


#leafletDiv {
    height: 100%;
}

.leaflet-popup-content-wrapper {
    background-color: var(--background-color);
    color: var(--foreground-color);
    border: 2px solid var(--popup-border);
    box-shadow: 0 3px 14px var(--shadow-color) !important;
}

.leaflet-container {
    background-color: var(--background-color) !important;
}

.leaflet-popup-tip {
    background-color: var(--popup-border) !important;
    color: var(--popup-border) !important;
    box-shadow: 0 3px 14px var(--shadow-color) !important;
}

#geolocate-button {
    position: absolute;
    bottom: 25px;
    right: 50px;
}

#geolocate-button img {
    width: 31px;
    height: 31px;
    margin: 6px;
}

#geolocate-button > .uielement {
    display: block;
}


#help-button-mobile {
    display: none;
}

#layer-selection {
    position: absolute;
    bottom: 1em;
    left: 1em;
    z-index: 9000;
    background-color: var(--background-color);
    color: var(--foreground-color);

    border-radius: 1em;
    cursor: pointer;
    box-shadow: 0 0 10px var(--shadow-color);
}

.single-layer-selection-toggle{
    position: relative;
    width:  2em;
    height: 2em;
}
.single-layer-selection-toggle img{
    max-height: 2em !important;
    max-width:  2em !important;
}

.single-layer-selection-toggle svg{
    max-height:2em !important;
    max-width: 2em !important;
}

.simple-add-ui-icon{
    position: relative;
    display: block;
    width:  3.5em;
    height: 3.5em;
    padding-right: 0.3em;
    padding-left: 0.3em;
}

.simple-add-ui-icon img{
    max-height:3.5em !important;
    max-width: 3.5em !important;
}

.simple-add-ui-icon svg{
    max-height:3.5em !important;
    max-width: 3.5em !important;
}

.layer-selection-toggle {
    border-radius: 1em;
    display: flex;
    flex-direction: column-reverse;
    background: var(--subtle-detail-color);
}

.layer-selection-toggle svg {
    display: block;
    width: 2em;
    height: 2em;
    padding: 0.75em;
}

/**************** GENERIC ****************/


.alert {
    background-color: var(--alert-color);
    font-weight: bold;
    border-radius: 1em;
    margin: 0.25em;
    text-align: center;
    padding: 0.15em 0.3em;
}

.question form {
    display: inline-block;
    max-width: 90vw;
    width: 100%;
}

.invalid {
    box-shadow: 0 0 10px #ff5353;
    height: min-content;
}

.shadow {
    box-shadow: 0 0 10px var(--shadow-color);
}

.title-font span {
    font-size: xx-large !important;
    font-weight: bold;
}

.soft {
    background-color: var(--subtle-detail-color);
    color: var(--subtle-detail-color-contrast);
    font-weight: bold;
    border-radius: 1em;
    margin: 0.25em;
    text-align: center;
    padding: 0.15em 0.3em;
}


.subtle {
    color: #999;
}

.bold {
    font-weight: bold;
}

.thanks {
    background-color: #43d904;
    font-weight: bold;
    border-radius: 1em;
    margin: 0.25em;
    text-align: center;
    padding: 0.15em 0.3em;
}

.clickable {
    pointer-events: all;
}

.unclickable {
    pointer-events: none !important;
}

.page-split {
    display: flex;
    height: 100%;
}


.activate-osm-authentication {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}


#searchbox {
    display: inline-block;
    text-align: left;
    background-color: var(--background-color);
    color: var(--foreground-color);

    transition: all 500ms linear;
    pointer-events: all;
    border-radius: 1.3em;
    margin: 0 0 0.5em;
    width: 100%;
}

.search {
    position: relative;
    float: left;
    height: 2em;
    margin-right: 0.5em;
}

#searchbox {
    width: 100%
}

#searchbox .form-text-field {
    position: relative;
    float: left;
    margin-top: 0.2em;
    margin-left: 1em;
    width: calc(100% - 4em)
}

#searchbox input[type="text"] {
    background: transparent;
    border: none;
    font-size: large;
    width: 100%;
    box-sizing: border-box;
    color: var(--foreground-color);
}

.search-go img {
    position: relative;
    float: right;
    height: 1.2em;
    border: 2px solid black;
    border-radius: 2em;
    padding: 0.4em;
    margin-left: 0.5em;
    margin-right: 0;
}


.add-popup-all-buttons {
    max-height: 50vh;
    display: inline-block;
    overflow-y: auto;
    width: 100%;
}


/**************************************/


#topleft-tools {
    display: block;
    position: absolute;
    z-index: 5000;
    transition: all 500ms linear;
    pointer-events: none;
    /* Shadow offset */
    padding: 0.5em 10px 0 0.5em;
}

.welcomeMessage {
    display: block;
    margin-left: 4em;
    max-width: calc(100vw - 5em);
    width: 40em;
    max-height: calc(100vh - 15em);
    overflow-y: auto;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    background-color: var(--background-color);
    color: var(--foreground-color);
}

.close-welcome-button {
    position: absolute;
    display: inline-block;
    height: 100%;
    background-color: var(--subtle-detail-color);
    color: var(--subtle-detail-color-contrast);
    box-sizing: border-box;
    width: 4em;
    padding: 1em;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
}

.close-welcome-button svg {
    width: 2em;
    height: 2em;
}

.open-welcome-button {
    display: inline-block;
    box-sizing: border-box;
    background: var(--subtle-detail-color);
    color: var(--foreground-color);

    height: 3.5em;
    width: 3.5em;
    padding: 0.75em;
    border-radius: 1em;
}

.open-welcome-button svg {
    width: 2em;
    height: 2em;
}

#messagesbox {
    /*Only shown on big screens*/
    position: relative;
    padding: 0;
    pointer-events: all;
    box-shadow: 0 0 10px var(--shadow-color);
    border-radius: 1em;
    width: min-content;
    background-color: var(--background-color);
    color: var(--foreground-color);
}


#centermessage {
    position: absolute;
    top: 30%;

    left: 25%;
    width: 50%;

    font-size: large;

    padding: 2em;
    border-radius: 2em;
    z-index: 4000;
    pointer-events: none;

    opacity: 1;
    background-color: var(--background-color);
    color: var(--foreground-color);


    transition: opacity 500ms linear;


    text-align: center;
    horiz-align: center;
    font-weight: bold;
}


.imgWithAttr {
    max-height: 20em;

    position: relative;
    overflow: hidden;

}

.attribution {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-weight: bold;
    font-size: smaller;

    position: absolute;
    bottom: 0;
    left: 6em; /* Offset for the go left button*/
    padding: 0.25em;
    margin-bottom: 0.25em;
    border-radius: 0.5em;

}

.attribution img {
    height: 1.2em !important;
    padding-right: 0.5em;
    padding-left: 0.2em;

}

.attribution-author {
    display: inline-block;
}

.license {
    font-size: small;
    font-weight: lighter;
}

.attribution a {
    color: white;
}


/***************** Info box (box containing features and questions ******************/


.map-attribution img {
    width: 1em;
    height: 1em;
    fill: black;
    border-radius: 0;
}

.leaflet-popup-content {
    width: 45em !important;
}

.leaflet-div-icon {
    background-color: unset !important;
    border: unset !important;
}


.leaflet-div-icon svg {
    width: calc(100%);
    height: calc(100%);
}

/****** ShareScreen *****/

.literal-code {
    display: inline-block;
    background-color: lightgray;
    padding: 0.5em;
    word-break: break-word;
    color: black;
    box-sizing: border-box;
}


.iframe-escape {
    background-color: var(--background-color);
    color: var(--foreground-color);

    border-radius: 2em;
    display: block;
    width: min-content;
}

.iframe-escape img {
    padding: 1em;
    width: 2em;
    height: 2em;
}

/** Switch layout **/
.subtle-button {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    font-size: large;
    margin: 0.5em;
    background-color: var(--subtle-detail-color);
    color: var(--subtle-detail-color-contrast);
    border-radius: 1em;
    align-items: center;
    text-decoration: none;
}


.subtle-button a {
    text-decoration: unset !important;
    color: unset !important;
    display: block ruby;
}

.round-button .subtle-button {
    width: 2em;
    height: 2em;
    border-radius: 1em;
    display: block !important;
    margin: 0;
    padding: 0.5em;
}

.small-button .subtle-button {
    height: 2em;
}

.small-button .subtle-button img {
    max-height: 1.8em;
}

.subtle-button img {
    max-width: 3em;
    height: 3em;
    margin-right: 0.5em;
    padding: 0;
    padding-bottom: 0.2em;
}


.small-image img {
    height: 1em;
    max-width: 1em;
}


.share-button {
    background-color: var(--subtle-detail-color);
    border: none;
    color: var(--subtle-detail-color-contrast);
    text-decoration: none;
    display: inline-block;
    border-radius: 3em;
    height: 2.5em;
    width: 2.5em;
    box-sizing: border-box;
    padding:0;
}

.share-button svg {
    height: 1.5em;
    width: 1.5em;
    padding: 0.5em;
    padding-left: 0.4em;
    fill: var(--subtle-detail-color-contrast) !important;
    stroke: var(--subtle-detail-color-contrast) !important;
}

.share-button svg path{
    fill: var(--subtle-detail-color-contrast) !important;
    stroke: var(--subtle-detail-color-contrast) !important;
}

.share-button svg circle{
    fill: var(--subtle-detail-color-contrast) !important;
    stroke: var(--subtle-detail-color-contrast) !important;
}
